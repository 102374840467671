import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import {  CardapioResultFirebaseType } from '@mobile/app/cardapio/cardapio.enum';
import { map, tap } from 'rxjs/operators';


@Injectable()
export class FirebaseProvider {
  constructor(private afs: AngularFirestore) {}


  watchPedido(id: string) {
    return this.afs
      .collection('Pedidos')
      .doc(id)
      .snapshotChanges()
      .pipe(map(changes => changes.payload.data())
    );
  }

  //Create user on firestore
  postUser = data =>
    this.afs
      .collection("Users")
      .doc(data.uid)
      .set(data);

  getUser(uid){
    return this.afs.firestore.collection('Users').doc(uid)
    .get();
  }
  getPedido(id){
    return new Promise((resolve/*, reject*/) =>{
      this.afs.firestore.collection('Pedidos').doc(id).get()
      .then((r) => {
        resolve(r.data());
      });
    });
  }
  getCupom(cupom){
    return new Promise((resolve/*, reject*/) =>{
      this.afs.firestore.collection('Cupom').where('cupom', '==', cupom).get()
      .then((r) => {
        if(r.size > 0){
          let array = [];
          r.forEach((d) => {
            let item = d.data();
            array.push(item);
          });
          resolve(array);
        }else{
          resolve(null);
        }
      });
    });
  }

  postPedido = data =>
    this.afs
      .collection("Pedidos")
      .doc(data.id)
      .set(data);

  getUltimosPedidos(uid){
    return new Promise((resolve/*, reject*/) =>{
      this.afs.firestore.collection('Pedidos')
      .orderBy('criadoEm')
      .where("user_id", "==", uid).where("status", "==",4)
      .limitToLast(5)
      .get()
      .then((r) => {
        let array = [];
        r.forEach((d) => {
          let item = d.data();
          item.criadoEm = item.criadoEm.toDate();
          array.push(item);
        });
        resolve(array.sort((a, b) => a.criadoEm < b.criadoEm ? 1 : (a.criadoEm > b.criadoEm ? -1 : 0)));
      });
    });
  }
  getSlides(){
    return new Promise((resolve/*, reject*/) =>{
      this.afs.firestore.collection('Slides').get()
      .then((r) => {
        let array = [];
        r.forEach((d) => {
          let item = d.data();
          array.push(item);
        });
        resolve(array);
      });
    });
  }

  getCardapio<
    Type extends keyof CardapioResultFirebaseType,
    SubType extends keyof CardapioResultFirebaseType[Type],
    ResultType extends CardapioResultFirebaseType[Type][SubType]
  >(type: Type, subtype: SubType): Promise<ResultType> {

    return new Promise<ResultType>((resolve, reject) =>{

      try {

        let query = this.afs.firestore
          .collection('Cardapio')
          .doc(type.toLowerCase())
          .collection(subtype.toString().toLowerCase());

        // ORDER BY esta dando erro em tempo de execucao
        //   query = query.orderBy('name');

        // if (type === 'PIZZAS' && subtype === 'BORDAS') {
        //   // Quando for bordas ordena pelo preco
        //   query = query.orderBy('price');
        // }

        query.get()
          .then((r) => {
            let array = [] as ResultType & Array<any>;
            r.forEach((d) => {
              let item = d.data();
              array.push(item as any);
            });
            resolve(array);
          })
          .catch(err => {
            console.error('erro na listagem, err', err);
            throw 'erro na listagem do cardápio'
          });

      } catch(err) {
        console.error('erro trycatch, err', err);
        reject(err);
      };

    });

  }

  getBairros(){
    return new Promise((resolve/*, reject*/) =>{
      this.afs.firestore.collection('Tarifa')
      .where('ativo', '==', true)
      .orderBy('bairro').get()
      .then((r) => {
        let array = [];
        r.forEach((d) => {
          let item = d.data();
          array.push(item);
        });
        resolve(array);
      });
    });
  }

  getTarifa(bairro){
    return new Promise((resolve/*, reject*/) =>{
      this.afs.firestore.collection('Tarifa').where('bairro', '==', bairro).get()
      .then((r) => {
        let array = [];
        r.forEach((d) => {
          array.push(d.data());
        });
        resolve(array);
      });
    });
  }

  /**
   * @deprecated by getLojaFechada
   */
  getStatus(){
    return new Promise((resolve/*, reject*/) =>{
      this.afs.firestore.collection('Status').doc('status').get()
      .then((r) => {
        resolve(r.data());
      });
    });
  }

  getLojaFechada() {
    return this.afs.collection<any>('Status')
      .doc('status')
      .snapshotChanges()
      .pipe(
        map(a => (a.payload.data()?.status as boolean) ?? false),
        tap(
          a => {
            a;
            // console.log('loja aberta?', a, this.afs.firestore.serverTimestamp )
          }
        )
      )
  }

}
