import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, NavController, ViewWillEnter } from '@ionic/angular';
import { ModalController, ToastController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { MessagingService } from '@mobile/app/services/messaging/messaging.service';
import { PedidoService } from '@mobile/app/services/pedido/pedido.service';
import { TaxaEntrega } from '@mobile/core/models/taxa-entrega.model';
import Utils from '@mobile/helpers/utils';
import slugify from 'slugify';

import { FirebaseProvider } from '../../providers/firebase';
import { EnderecosPage } from '../enderecos/enderecos.page';
import { TotalPartsEnum } from '../enuns/total-parts.enum';
import { FcmService } from '../services/fcm.service';
import { IAdicional, IBorda, IProduto, IProdutoPizza } from '@mobile/models/produtos/produto.model';

export interface Cupom {
  id: string | number;
  cupom: string;
  type: 'porcentagem' | 'valor';
  value: number;
}

type PedidoItem = IProduto & IProdutoPizza & {
  adicionais: IAdicional[];
  borda?: IBorda;
  itens?: IProdutoPizza[];
  qtd: number;
  obs: string;
};

@Component({
  selector: 'app-sacola',
  templateUrl: './sacola.page.html',
  styleUrls: ['./sacola.page.scss'],
})
export class SacolaPage implements OnInit, ViewWillEnter {

  readonly TotalPartsEnum = TotalPartsEnum;

  pagamento: any;
  podePagarComPontos = false;
  troco: any = null;
  cupom?: Cupom;
  pedido?: PedidoItem[];
  user?: any = [];
  subtotal: number = 0;
  entrega: boolean = true;
  taxa_entrega: number = 0;
  taxaEntregaClass: TaxaEntrega;
  total: number = 0;
  endereco?: EnderecoType;
  disabled = false;
  pontosPedido = 0;
  pedidoPontosAtivo = true;
  qtd_promo = 0;
  warn = false;

  lojaAberta$ = this.firebaseProvider.getLojaFechada();

  constructor(
    private modalCtrl: ModalController,
    private navCtrl: NavController,
    private router: Router,
    private alertController: AlertController,
    private storage: Storage,
    private firebaseProvider: FirebaseProvider,
    private fcmService: FcmService,
    private messagingService: MessagingService,
    private toastCtrl: ToastController,
    private pedidoService: PedidoService,
  ) {
    this.taxaEntregaClass = new TaxaEntrega(this.entrega, 0, 0);
  }

  ngOnInit() {
    this.subscribePedido();
  }

  ionViewWillEnter() {
    this.validaPedidos();
  }

  subscribePedido() {
    this.pedidoService.getPedido().subscribe(
      pedido => {
        this.pedido = pedido;
        this.validaPedidos();
      }
    );
  }

  /**
   * Reseta todos os estados de variaveis da Sacola
   */
  reset() {
    this.pagamento = undefined;
    this.podePagarComPontos = false;
    this.troco = null;
    this.cupom = undefined;
    this.pedido;
    this.user = [];
    this.subtotal = 0;
    this.entrega = true;
    this.taxa_entrega = 0;
    this.taxaEntregaClass = new TaxaEntrega(this.entrega, 0, 0);
    this.total = 0;
    this.endereco = undefined;
    this.disabled = false;
    this.pontosPedido = 0;
    this.pedidoPontosAtivo = true;
    this.qtd_promo = 0;
    this.warn = false;
  }

  async validaPedidos() {
    if (this.pedido) {
      this.pedidoPontosAtivo = await this.storage.get('pedidoPontos');
      // removido o carregamento do endereço para que o usuario sempre
      // selecione o endereco que ele vai querer
      // this.endereco = await this.getEndereco();
      // if (!this.endereco) {
      //   this.abrirEndereco();
      //   this.closeModal();
      // }
      this.user = await this.getUsuario();
      this.podePagarComPontos = this.validaPedidoPontos(this.pedido, this.user);
      this.getTaxaEntrega();
      this.atualizarTotal();
    }
  }

  validaPedidoPontos(pedido, user) {
    this.qtd_promo = 0;
    if (user?.pontos_fidelidade >= 10 && !this.pedidoPontosAtivo) {
      for (let item of pedido) {
        if (!item.promo) {
          this.alertController
            .create({
              header: 'Para pagar com seus pontos adicione no pedido somente pizzas da promoção!',
              buttons: [
                {
                  text: 'Entendi',
                  role: 'cancel'
                }
              ]
            })
            .then((a) => {
              if (!this.warn) {
                a.present();
                this.warn = true;
              }
            });
          this.qtd_promo = 0;
          return false;
        }
        this.qtd_promo += item.qtd;
      }
      return (user.pontos_fidelidade - 10 * this.qtd_promo) >= 0 ? true : false;
    }
    return false;
  }

  getTaxaEntrega() {
    this.taxaEntregaClass.entrega = this.entrega && !!this.endereco;
    if (this.entrega && this.endereco) {
      this.firebaseProvider.getTarifa(this.endereco.bairro).then((r) => {
        try {
          this.taxaEntregaClass.taxa_bairro = r[0].valor;
        } catch (e) {
          this.taxaEntregaClass.taxa_bairro = 0;
        }
        this.taxa_entrega = this.taxaEntregaClass.calcTaxaEntrega(
          this.subtotal
        );
        this.atualizarTotal();
      });
    } else {
      // Se não for entrega Zera(0) a taxa de entrega (Balcâo)
      this.taxa_entrega = this.taxaEntregaClass.calcTaxaEntrega(this.subtotal);
    }
  }
  pagamentoDin() {
    this.pagamento = 'dinheiro';
    this.alertTroco('');
    this.atualizarTotal();
  }
  pagamentoCard() {
    this.pagamento = 'card';
    this.atualizarTotal();
  }
  pagamentoPix() {
    this.pagamento = 'pix-entrega';
    this.atualizarTotal();
  }

  pagamentoPontos() {
    if (this.user.pontos_fidelidade >= 10) {
      this.pagamento = 'pontos';
      this.atualizarTotal();
    }
  }
  atualizarTotal() {
    const subtotal: number = (this.pedido ?? []).reduce(
      (prevVal: number, item: any) => {
        const price_adicionais = (item.adicionais || []).reduce(
          (price, adc) => price + Utils.getPriceAdicional(adc, item.broto),
          0
        );
        const price_borda = Utils.getPriceAdicional(item.borda, item.broto);
        const pizza_price = item.broto ? item.price_broto : item.price;

        prevVal =
          prevVal + (pizza_price + price_adicionais + price_borda) * item.qtd;
        return prevVal;
      },
      0
    );

    //duas casas decimais
    this.subtotal = Utils.casasDecimais(subtotal, 2);

    if (this.cupom) {
      switch(this.cupom.type) {
        case 'valor':
          this.subtotal -= Utils.casasDecimais(this.cupom.value, 2);
          break;
        case 'porcentagem':
          this.subtotal = Utils.casasDecimais(
            this.subtotal * ((100 - this.cupom.value) / 100),
            2
          );
        break;
      }
    }

    if (this.pagamento === 'pontos') {
      this.subtotal = 0;
    }

    this.taxa_entrega = this.taxaEntregaClass.calcTaxaEntrega(this.subtotal);

    this.total = Number(this.subtotal) + Number(this.taxa_entrega);
    this.total = parseFloat(this.total.toFixed(2));
  }

  async getUsuario() {
    const user = await this.storage.get('user');
    if (!user) {
      return;
    }
    const userAtt = await this.firebaseProvider.getUser(user['uid']);
    return userAtt.data();
  }

  async getEndereco() {
    const enderecos = await this.storage.get('endereco');
    if (enderecos && enderecos.cadastrados.length > 0) {
      const selecionado = enderecos.cadastrados.findIndex((end: any) => {
        return end.id === enderecos.selecionado;
      });
      return enderecos.cadastrados[selecionado];
    }
    return null;
  }

  async getPedido() {
    // let pedidoAtual = await this.storage.get('pedido');
    let pedidoAtual = await this.pedidoService.getPedido().toPromise();

    pedidoAtual.forEach((item) => {
      if ((item.type === 'salgada' || item.type === 'doce') && !item.promo) {
        this.pontosPedido += item.qtd;
      }
    });
    return pedidoAtual && pedidoAtual.length > 0 ? pedidoAtual : false;
  }
  async confirmarRemover(index) {
    const alert = await this.alertController.create({
      header: 'Deseja remover o item?',
      buttons: [
        {
          text: 'Não!',
          role: 'cancel',
        }, {
          text: 'Sim!',
          handler: () => {
            this.pedido?.splice(index, 1);
            this.atualizarTotal();
            // this.storage.set('pedido', this.pedido);
            this.pedidoService.setPedido(this.pedido);
            this.podePagarComPontos = this.validaPedidoPontos(
              this.pedido,
              this.user
            );
          }
        }
      ]
    });
    alert.present();
  }
  async removerItem(id: any) {
    const index = this.pedido.findIndex((item: any) => {
      return item.id === id;
    });
    if (this.pedido[index].qtd === 1) {
      this.confirmarRemover(index);
    } else {
      this.pedido[index].qtd--;
      // this.storage.set('pedido', this.pedido);
      this.pedidoService.setPedido(this.pedido);
      this.atualizarTotal();
      this.podePagarComPontos = this.validaPedidoPontos(this.pedido, this.user);
    }
  }
  addItem(id: any) {
    const index = this.pedido.findIndex((item: any) => {
      return item.id === id;
    });
    this.pedido[index].qtd++;
    this.atualizarTotal();
    // this.storage.set('pedido', this.pedido);
    this.pedidoService.setPedido(this.pedido);
    this.podePagarComPontos = this.validaPedidoPontos(this.pedido, this.user);
  }

  async alertTroco(warn) {
    const alert = await this.alertController.create({
      header: 'Precisa de troco?',
      subHeader: warn,
      inputs: [
        {
          name: 'troco',
          type: 'text',
          placeholder: 'Para quanto ?'
        },
      ],
      buttons: [
        {
          text: 'Não preciso',
          handler: () => {
            this.troco = 0;
          }
        }, {
          text: 'Ok',
          handler: (data) => {
            parseFloat(data.troco);
            if (data.troco > this.total) {
              this.troco = parseFloat(data.troco);
            } else {
              this.alertTroco('O valor deve ser maior que o total do pedido.');
            }
          }
        }
      ]
    });
    await alert.present();
    this.troco = 0;
  }
  async inputCupom(msg: Cupom) {

    const alert = await this.alertController.create({
      header: 'Cupom de desconto',
      subHeader: msg ? msg.cupom : '',
      inputs: [
        {
          name: 'cupom',
          type: 'text',
          placeholder: 'Insira aqui'
        },
      ],
      buttons: [
        {
          text: 'Remover',
          role: 'cancel',
          handler: () => {
            this.cupom = undefined;
            this.atualizarTotal();
          }
        }, {
          text: 'Aplicar',
          handler: (data) => {
            const cupom = data.cupom
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .toLowerCase();
            if (cupom) {
              this.firebaseProvider
                .getCupom(slugify(cupom))
                .then(async (res: Cupom[]) => {
                  if (res) {
                    this.cupom = res[0];
                    console.log(this.cupom);
                    const toast = await this.toastCtrl.create({
                      message: 'Cupom adicionado!',
                      duration: 1000,
                      color: 'secondary',
                      position: 'top',
                    });
                    toast.present();
                    this.atualizarTotal();
                  } else {
                    this.inputCupom({ cupom: 'Cupom não encontrado!' } as any);
                  }
                });
            }
          }
        }
      ]
    });
    await alert.present();
  }

  async validaPedido() {
    if (!this.endereco && this.entrega) {
      this.abrirEndereco();
      return false;
    } else if (!this.pagamento) {
      const toast = await this.toastCtrl.create({
        message: 'Selecione a forma de pagamento!',
        duration: 1200,
        color: 'danger',
        position: 'top',
      });
      toast.present();
    } else if (this.entrega && !this.taxa_entrega) {
      const toast = await this.toastCtrl.create({
        message: 'Problemas para definir a taxa de entrega, cadastre novamente seu endereço.',
        duration: 1500,
        color: 'danger',
        position: 'top',
      });
      toast.present();
    }
    return ((this.endereco && this.entrega) || !this.entrega) &&
      this.pagamento &&
      (!this.entrega || (this.entrega && this.taxa_entrega))
      ? true
      : false;
  }

  /**
   * Acumulador de pontos que o pedido tem
   */
  private pontosDoPedido(itens: any[]): number {

    const PRIMEIRO_NUMERO_PIZZA_ESPECIAIS = 35; // 35 - 4 queijos

    /**
     * Valida se a parte do item do Pedido a "parte" do pedido é da pizza especial
     * @param parte
     * @returns
     */
    const validaParteDoPedido = (parte) => {
      const numero = parseInt(parte.name) || 0;
      return numero >= PRIMEIRO_NUMERO_PIZZA_ESPECIAIS;
    };

    /**
     * Valida se o item do pedido é pizza especial
     * @param pedido
     * @returns
     */
    const validaItemDoPedido = (pedido): boolean => {

      if (pedido.broto) {
        return false;
      }

      if (pedido.itens) {
        return pedido.itens.every(validaParteDoPedido);
      } else {

      }
      const numero = parseInt(pedido.name) || 0;

      return numero >= PRIMEIRO_NUMERO_PIZZA_ESPECIAIS;
    };

    let pontos_acc = 0;

    itens.forEach(itemPedido => {
      if (validaItemDoPedido(itemPedido)) {
        pontos_acc++;
      }
    });

    return pontos_acc ?? 0;
  }

  async autenticarUsuario() {
    if (!this.user) {
      this.navCtrl.navigateRoot(['/auth'], {
        queryParams: {
          redirectUrl:'/tabs/sacola'
        }
      });
      return;
    }
  }

  async confirmarPedido() {

    if (!this.user) {
      this.navCtrl.navigateRoot(['/auth'], {
        queryParams: {
          redirectUrl:'/tabs/sacola'
        }
      });
      return;
    }

    const validaPedido = await this.validaPedido();
    if (validaPedido) {
      this.disabled = true;
      this.atualizarTotal();
      const itens = [];
      this.pedido.forEach((item) => {
        // console.log('itens do pedido', item);

        const name = ({ name, broto }) => (broto ? `(BROTO) ${name}` : name);
        let retorno: {
          codigo_interno?: string,
          broto: boolean;
          meioameio: boolean;
          name: string;
          qtd: number;
          price: number;
          obs?: string;
          itens?: { name: string; price: number }[];
          adicionais?: { name: string; price: number }[];
          borda?: { name: string; price: number };
        };

        // tslint:disable-next-line: variable-name
        const price_adicionais = (item.adicionais || []).reduce(
          (price, adc) => price + Utils.getPriceAdicional(adc, item.broto),
          0
        );
        // tslint:disable-next-line: variable-name
        const price_borda = Utils.getPriceAdicional(item.borda, item.broto);

        if (item.meioameio) {
          retorno = {
            codigo_interno: item.codigo_interno ?? null,
            broto: item.broto,
            meioameio: item.meioameio,
            name: item.name,
            qtd: item.qtd,
            price:
              (item.broto ? item.price_broto : item.price) +
              price_adicionais +
              price_borda,
            itens: item.itens!.map((i) => ({
              name: name(i),
              price: item.broto ? item.price_broto : item.price,
            })),
            adicionais: item.adicionais ? item.adicionais : null,
            borda: item.borda,
          };
        } else {
          retorno = {
            codigo_interno: item.codigo_interno ?? null,
            broto: item.broto || false,
            meioameio: item.meioameio || false,
            name: name(item),
            qtd: item.qtd,
            price: (item.broto ? item.price_broto : item.price) + price_adicionais + price_borda,
            adicionais: item.adicionais ? item.adicionais : null,
            borda: item.borda ? item.borda : null,
          };
        }
        if (item.obs) {
          retorno = {
            ...retorno,
            obs: item.obs,
          };
        }
        itens.push(retorno);
      });

      const now = new Date();
      const data = now.toLocaleString(['pt-BR'], {
        day: '2-digit',
        month: '2-digit',
        year: '2-digit',
      });
      const horario = now.toLocaleString(['pt-BR'], {
        hour: '2-digit',
        minute: '2-digit',
      });
      let valorSemCupom: any = null;
      let cupomUtilizado = this.cupom ? this.cupom : null;
      if (cupomUtilizado && cupomUtilizado.type === 'valor') {
        valorSemCupom = parseFloat((this.subtotal + cupomUtilizado.value).toFixed(2));
      } else if (cupomUtilizado && this.cupom.type === 'porcentagem') {
        valorSemCupom = parseFloat(
          (this.subtotal / ((100 - cupomUtilizado.value) / 100)).toFixed(2)
        );
      }
      if (this.pagamento === 'pontos') {
        this.pontosPedido = 0;
      }
      let token;
      try {
        // Token do PushNotifications
        token = this.fcmService.getToken();
      } catch (e) {
        // Token do Service Workers
        const source: Promise<string> = this.messagingService.getToken().toPromise();
        token = await source;
        console.warn('Token do PushNotifications: error', e);
      }

      if (!token) {
        try {
          // Token do Service Workers
          const source: Promise<string> = this.messagingService.getToken().toPromise();
          token = await source;
        } catch (e) {
          console.warn('Token do Service Workers: error', e);
        }
      }

      let enderecoCompleto = 'Like Pizzas - Rua Elias Joaquim das Silva, 309 - Guaruja SP';

      if (this.entrega) {
        enderecoCompleto =
          `${this.endereco.rua}, ${this.endereco.numero}` +
          (this.endereco.complemento ? `,  ${this.endereco.complemento}` : '') +
          `, ${this.endereco.bairro}` +
          (this.endereco.referencia ? `, Ref: ${this.endereco.referencia}` : '');
      }

      // Organizando dados
      const pedido = {
        contato: this.user.tel,
        endereco: enderecoCompleto,
        entrega: this.entrega,
        entregador: null,
        criadoEm: now,
        data_pedido: data,
        horario_pedido: horario,
        horario_entrega: null,
        id: (Date.now() + Math.random())
          .toString()
          .replace('.', '')
          .substr(2, 9),
        nome_usuario: this.user.name,
        pagamento: this.pagamento,
        pedido: itens,
        status: 1,
        qtd_pizza_pontos: this.qtd_promo, // ?? sera se n"ao deveria ser this.pontosPedido
        subtotal: this.subtotal,
        cupom: cupomUtilizado,
        subtotal_sem_cupom: valorSemCupom,
        taxa_entrega: this.taxa_entrega,
        total: this.total,
        troco: this.troco,
        user_id: this.user.uid,
        pontos: this.pontosDoPedido(itens), // this.pontosPedido,
        token: token || null,
      };

      // console.log('pedido', pedido)

      // Subindo pedido no firestore
      this.firebaseProvider.postPedido(pedido).then(() => {
        const pedidos = [];
        this.storage
          .get('pedidoEfetuado')
          .then((res) => {
            if (res) {
              res.forEach((ped) => {
                pedidos.push(ped);
              });
            }
          })
          .then(() => {
            pedidos.push(pedido.id);
            // this.storage.set('pedidoEfetuado', pedidos);
            this.pedidoService.setPedidoEfetuado(pedidos);
            if (this.pagamento === 'pontos') {
              this.storage.set('pedidoPontos', true);
            }
          })
          .then(() => {
            this.pedidoService.removePedido();
            // this.closeModal();
            this.router.navigate(['/tabs/status'])
            this.reset();
          });
      });
    }
  }

  calcPriceTotalPedido(pedido): number {
    // tslint:disable-next-line: variable-name
    const price_pedido = pedido.broto ? pedido.price_broto : pedido.price;
    // tslint:disable-next-line: variable-name
    const price_adicionais = (pedido.adicionais || []).reduce(
      (price, adc) => price + Utils.getPriceAdicional(adc, pedido.broto),
      0
    );
    // tslint:disable-next-line: variable-name
    const price_borda = Utils.getPriceAdicional(pedido.borda, pedido.broto);
    return Utils.casasDecimais(
      (price_pedido + price_adicionais + price_borda) * pedido.qtd,
      2
    );
  }

  async closeModal() {
    await this.modalCtrl.dismiss();
  }
  async abrirEndereco() {
    const modal = await this.modalCtrl.create({
      component: EnderecosPage,
      componentProps: {isModalSelect: true}
    });

    modal.present();
    const { data: endereco } = await modal.onDidDismiss<EnderecoType>();
    if (endereco) {
      this.endereco = endereco;
      this.getTaxaEntrega();
    }

  }

  goToHome() {
    this.router.navigateByUrl('/tabs/home');
    // this.closeModal();
  }

  calculoPrice(pizza) {
    return Utils.calculoPrice(pizza.price, pizza.Broto);
  }
}
