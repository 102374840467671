import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalize'
})
export class CapitalizePipe implements PipeTransform {

  transform(str: any): any {
    if (!str) return str;
    return str[0].toUpperCase() + str.substring(1).toLowerCase();
  }

}
