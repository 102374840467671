// export const environment = {
//   production: false,
//   firebaseConfig: {
//     apiKey: 'AIzaSyBNG0hbNULIHQ0oSUhHKDT0t1_fTb9WXD0',
//     authDomain: 'pizzaria-68f26.firebaseapp.com',
//     projectId: 'pizzaria-68f26',
//     storageBucket: 'pizzaria-68f26.appspot.com',
//     messagingSenderId: '442075768410',
//     appId: '1:442075768410:web:7322637ff910718f845a63'
//   }
// };

// export const notificationKey = 'key=AAAA_7hBaCo:APA91bED2_Vxib-3emkAXjQSoWleHBeWJJfJ9im_471rHJp8IAuPyuhyAPtEo1_SvnWlYEwd_tSzvuc9zd0L4VNGACTndGL9hL5Ez-TpsKGqRD6qWqjwIIZzLwNfhDWJR1HhzxgFQyuP';

// export const adminCredentials = {
//   email: 'igor@email.com',
//   password: '123456'
// };




export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: 'AIzaSyBNG0hbNULIHQ0oSUhHKDT0t1_fTb9WXD0',
    authDomain: 'pizzaria-68f26.firebaseapp.com',
    projectId: 'pizzaria-68f26',
    storageBucket: 'pizzaria-68f26.appspot.com',
    messagingSenderId: '442075768410',
    appId: '1:442075768410:web:7322637ff910718f845a63'
  }
};

export const notificationKey = 'key=AAAAZu3E5lo:APA91bE-omiUnHMQ1Zf4aU17Rq_yCD6x2FJVAlU5nVMGdOlVylPWqLuQVar10DRsUyT_UZB4tDk6rflgeBnH9gLPY5lSj5CgNNlB8XS6uKRV_fQ0qCekxDtzrS1iissDmBNxjBlG2POy';

export const adminCredentials = {
  email: 'igor@email.com',
  password: '123456'
};
