import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'camelcase'
})
export class CamelcasePipe implements PipeTransform {

  transform(str: string): string {

    return str[0].toUpperCase() + str.substring(1)
    
    // return str.toLowerCase()
    //   .replace(/[^a-zA-Z0-9]+(.)/g, (_, chr) => chr.toUpperCase());
  
  }

}
