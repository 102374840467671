import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'internalCode'
})
export class InternalCodePipe implements PipeTransform {

  transform(value: string, internalCode?: string): unknown {

    if (internalCode) {
      if (/^\d+\s?-/.test(value)) {
        return `${internalCode} - ${value.replace(/^\d+\s?-/, '')}`;
      }
      return `${internalCode} - ${value}`;
    }

    return value;
  }

}
