import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ObjectKeysPipe } from './object-keys.pipe';
import { CamelcasePipe } from './camelcase.pipe';
import { CapitalizePipe } from './capitalize.pipe';
import { InternalCodePipe } from './internal-code/internal-code.pipe';



@NgModule({
  declarations: [ObjectKeysPipe, CamelcasePipe, CapitalizePipe, InternalCodePipe,],
  exports: [ObjectKeysPipe, CamelcasePipe, CapitalizePipe, InternalCodePipe,],
  imports: [
    CommonModule
  ]
})
export class PipesModule { }
