export enum TotalPartsEnum {
  /**
   * Pizza ou Broto 2 Sabores
   */
  MEIO_A_MEIO = 2,

  /**
   * Pizza Trem 4 Sabores
   */
  TREM = 4,

  /**
   * Pizza BI Trem 6 Sabores
   */
  BI_TREM = 6,

  /**
   * Pizza Mega BI Trem 8 Sabores
   */
  MEGA_BI_TREM = 8,
}
