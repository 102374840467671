import { PriceHelper } from '@mobile/app/helpers/price.helper';

export interface IProduto {
  codigo_interno?: string;
  id: string;
  name: string;
  description: string;
  price: number;
  promo: null;
  photo: string;
}

export interface IAdicional extends IProduto {
  id: string;
  name: string;
  custom_type: ""
  price_broto?: number;

  sub_type: 'option'
  type: string;
}

export interface IBorda extends IAdicional {
  type: 'bordas'
}

export interface IStatusProduto {
  status: boolean;
}


export interface IAdicionalStatus extends IAdicional, IStatusProduto { };



type TypePizza = 'doce' | 'salgada';
type SubTypePizza = 'option' | 'Pizzas tradicionais' | 'Pizzas especiais';
export interface IProdutoPizza extends IProduto {
  broto: boolean;
  price_broto: number;
  price_trem: number;
  price_bi_trem: number;
  meioameio: boolean;
  sub_type: SubTypePizza;
  type: TypePizza;
};
export interface IProdutoBebida extends IProduto {
  sub_type: "option"
  type: "bebida"
};

export interface IProdutoEsfirrao extends IProduto {
  sub_type: "salgadas" | "doces";
  type: "esfirrao";
};

export type PriceType = keyof Pick< IProdutoPizza, "price" | "price_broto" | "price_trem" | "price_bi_trem"> | "price_mega_bi_trem";

export class ProdutoModel implements IProduto {
  sub_type: SubTypePizza;
  type: TypePizza;
  id: string;
  name: string;
  description: string;
  price: number;
  promo: null;
  photo: string;

  constructor(produto: IProdutoPizza) {
    produto && Object.assign(this, produto);
  }
}

export class ProdutoPizzaModel implements IProdutoPizza {
  codigo_interno?: string;
  broto: boolean = false;
  meioameio: boolean = false;
  sub_type: SubTypePizza;
  type: TypePizza;
  id: string;
  name: string;
  description: string;
  price: number;
  price_broto: number;
  price_trem: number;
  price_bi_trem: number;
  promo: null;
  photo: string;

  constructor(produto: IProdutoPizza) {
    produto && Object.assign(this, produto);
  }

}
export class ProdutoPizzaMeioameioModel implements Omit<IProdutoPizza, 'description' | 'type'> {
  broto: boolean = false;
  trem: boolean = false;
  biTrem: boolean = false;
  price_broto: number;
  price_trem: number;
  price_bi_trem: number;
  meioameio: boolean = false;
  sub_type: SubTypePizza;
  // type: TypePizza;
  id: string;
  name: string = 'Meio-a-Meio';
  // description: string;
  price: number;
  promo: null;
  photo: string;

  private parts: []
    | [part1: IProdutoPizza]
    | [part1: IProdutoPizza, part2: IProdutoPizza]
    | [part1: IProdutoPizza, part2: IProdutoPizza, part3: IProdutoPizza]
    | [part1: IProdutoPizza, part2: IProdutoPizza, part3: IProdutoPizza, part4: IProdutoPizza]
    | [part1: IProdutoPizza, part2: IProdutoPizza, part3: IProdutoPizza, part4: IProdutoPizza, part5: IProdutoPizza]
    | [part1: IProdutoPizza, part2: IProdutoPizza, part3: IProdutoPizza, part4: IProdutoPizza, part5: IProdutoPizza, part6: IProdutoPizza]
    | [part1: IProdutoPizza, part2: IProdutoPizza, part3: IProdutoPizza, part4: IProdutoPizza, part5: IProdutoPizza, part6: IProdutoPizza, part7: IProdutoPizza]
    | [part1: IProdutoPizza, part2: IProdutoPizza, part3: IProdutoPizza, part4: IProdutoPizza, part5: IProdutoPizza, part6: IProdutoPizza, part7: IProdutoPizza, part8: IProdutoPizza
  ] = [] as any;

  constructor(produto: Omit<IProdutoPizza & { parts: [] | [IProdutoPizza] | [IProdutoPizza, IProdutoPizza] } & {trem: boolean, biTrem: boolean} , 'id' | 'name' | 'broto' | 'meioameio'>) {
    produto && Object.assign(this, produto);
    this.trem = produto.trem ?? false;
    this.biTrem = produto.biTrem ?? false;
    this.parts = (produto.parts || []).map((part: IProdutoPizza) => new ProdutoPizzaModel(part)) as any;
    produto.price = this.broto ? PriceHelper.meioameioPriceBroto(produto.parts || []) : PriceHelper.meioameioPrice(produto.parts || []);
  }

  getParts() {
    return this.parts;
  }

  setPart(part: IProdutoPizza) {
    const newPart = new ProdutoPizzaModel(part);

    if (!this.parts?.length) {
      this.parts = [newPart];
    } else {
      this.parts.push(newPart as any);
      // this.price = this.broto ? PriceHelper.meioameioPriceBroto(this.parts || []) : PriceHelper.meioameioPrice(this.parts || []);
    }

    if (this.biTrem) {
      this.price =  PriceHelper.biTremPrice(this.parts || []);
    } else if (this.trem) {
      this.price =  PriceHelper.tremPrice(this.parts || []);
    } else if (this.broto) {
      this.price =  PriceHelper.meioameioPriceBroto(this.parts || []);
    } else  {
      this.price =  PriceHelper.meioameioPrice(this.parts || []);
    }

  }

  removePart(part: ProdutoPizzaModel) {
    const parts = this.getParts().filter((p: ProdutoPizzaModel) => part.id !== p.id);
    this.parts = parts as any;
  }

}

