import { Component, Input } from "@angular/core";

@Component({
  selector: "app-search-text",
  templateUrl: "./search-text.component.html",
  styleUrls: ["./search-text.component.scss"],
})
export class SearchTextComponent {
  @Input() searchText;
  constructor() {}
}
